import { GetServerSideProps } from 'next';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { signIn } from 'next-auth/react';
import { useEffect, useMemo, useState } from 'react';
import GoogleButton from 'react-google-button';

import Button from '@u9/bob3-shared/lib/components/Button/Button';
import FormInput from 'components/inputs/FormInput/FormInput';
import { useField } from 'components/inputs/hooks/useField';
import Logo from 'components/Logo/Logo';
import { useCopyStore } from 'store';
import { getCopy } from 'store/copy.data';
import { PageProps } from 'types/page';
import { useSvgIcon } from 'u9/hooks';
import { openPopup } from 'u9/utils/popup';
import { processJwt } from 'utils/nextApi';
import { NonModulesPages, ROUTES } from 'utils/routes';
import {
  autoLogoutRedirect,
  getCurrentPage,
  getIsAuthenticated,
  getSubdomain,
} from 'utils/server';

import * as Styled from './LoginPage.styles';

export const getServerSideProps: GetServerSideProps<PageProps> = async ctx => {
  const subdomain = getSubdomain(ctx.req);
  const { isAuthenticated, legacyToken } = await getIsAuthenticated(ctx);

  if (legacyToken) {
    return autoLogoutRedirect;
  }

  if (isAuthenticated) {
    return {
      redirect: {
        permanent: false,
        destination: '/',
      },
    };
  }

  const initialLocale = ctx.locale || 'en-gb';
  const initialCopy = getCopy(NonModulesPages.login, initialLocale, subdomain);
  const currentPage = getCurrentPage(ctx);

  const props = {
    subdomain,
    initialLocale,
    initialCopy,
    authData: null,
    dehydratedState: null,
    currentPage,
  };

  return {
    props,
  };
};

const LoginPage = ({ subdomain }: PageProps) => {
  const [isMounted, setMounted] = useState<boolean>(false);
  const router = useRouter();
  const copy = useCopyStore(s => s.copy);
  const [isCredentialsError, setIsCredentialsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { SvgIcon: EyeIcon } = useSvgIcon('eye');
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  const emailValidators = useMemo(
    () => ({
      email: { enabled: true, message: copy.app.validatorMessages.validEmail },
      required: {
        enabled: true,
        message: copy.app.validatorMessages.emailRequired,
      },
    }),
    [copy]
  );

  const [email, setEmail, isEmailValid, invalidEmailMessage] = useField(
    '',
    emailValidators
  );
  const passwordValidators = useMemo(
    () => ({
      required: {
        enabled: true,
        message: copy.app.validatorMessages.passwordRequired,
      },
    }),
    [copy]
  );
  const [password, setPassword, isPasswordValid, invalidPasswordMessage] =
    useField('', passwordValidators);

  const handleGoogleClick = () => {
    openPopup(
      `${process.env.NEXTAUTH_URL}${ROUTES.AUTH}?subdomain=${subdomain}`,
      450,
      730
    );
  };

  const handlePasswordLogin = async () => {
    setIsCredentialsError(false);

    try {
      setIsLoading(true);
      const res = await signIn('credentials', {
        username: email,
        password,
        workspace: subdomain,
        redirect: false,
      });
      if (res?.ok) {
        location.reload();
      }
      if (res?.error === 'CredentialsSignin') {
        setIsCredentialsError(true);
      }
    } catch (er) {
      console.error(er);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    setMounted(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!isMounted) return;

    const onMessage = async (event: MessageEvent) => {
      const data = typeof event.data === 'string' && JSON.parse(event.data);
      if (data.success === true && data.jwt) {
        await processJwt({ jwt: data.jwt });
        router.reload();
      }
    };

    window.addEventListener('message', onMessage);

    return () => {
      if (isMounted) window.removeEventListener('message', onMessage);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMounted]);

  const onPasswordChange = (value: string) => {
    setPassword(value);
    setIsCredentialsError(false);
  };

  const onEmailChange = (value: string) => {
    setEmail(value);
    setIsCredentialsError(false);
  };

  const handleShowNewPassword = () => {
    setIsPasswordVisible(prev => !prev);
  };

  return (
    <Styled.Background>
      <Styled.Wrapper>
        <Styled.LogoWrapper>
          <Logo />
        </Styled.LogoWrapper>
        <Styled.Title>{copy.login.title}</Styled.Title>
        <Styled.Subtitle>{copy.login.subtitle}</Styled.Subtitle>
        <Styled.FormWrapper>
          <FormInput
            value={email}
            onChange={onEmailChange}
            placeholder={copy.login.emailPlaceholder}
            error={invalidEmailMessage}
            theme="light"
          />
          <FormInput
            value={password}
            onChange={onPasswordChange}
            placeholder={copy.login.passwordPlaceholder}
            error={
              invalidPasswordMessage || isCredentialsError
                ? copy.login.credentialsError
                : ''
            }
            theme="light"
            type={isPasswordVisible ? 'text' : 'password'}
            endHtml={
              <Styled.ShowPasswordIcon onClick={handleShowNewPassword}>
                <EyeIcon />
              </Styled.ShowPasswordIcon>
            }
          />
        </Styled.FormWrapper>
        <Styled.ForgotPasswordButton>
          <Link href={ROUTES.FORGOT_PASSWORD}>{copy.login.forgotPassword}</Link>
        </Styled.ForgotPasswordButton>
        <Styled.ButtonWrapper>
          <Button
            label={copy.login.loginButton}
            disabled={!isEmailValid || !isPasswordValid || isLoading}
            onClick={handlePasswordLogin}
            loading={isLoading}
            icon="check"
          />
        </Styled.ButtonWrapper>

        <Styled.SubFormText>{copy.login.subFormText}</Styled.SubFormText>

        <GoogleButton
          type="light"
          onClick={handleGoogleClick}
          style={{ margin: 'auto' }}
        />
      </Styled.Wrapper>
    </Styled.Background>
  );
};

export default LoginPage;
