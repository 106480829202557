import Cookies from 'cookies';
import jwt from 'jsonwebtoken';
import {
  GetServerSidePropsContext,
  NextApiRequest,
  NextApiResponse,
} from 'next';
import NextAuth, { NextAuthOptions } from 'next-auth';
import { JWT } from 'next-auth/jwt';
import CredentialsProvider from 'next-auth/providers/credentials';
import GoogleProvider from 'next-auth/providers/google';

import { getApiClient } from 'utils/api';
import { ROUTES } from 'utils/routes';
import { SESSION_COOKIE_NAME } from 'utils/server';

export const cookieSettings = {
  httpOnly: true,
  sameSite: 'lax',
  path: '/',
  secure: true,
} as const;

export const getNextAuthOptions = (
  req: GetServerSidePropsContext['req'],
  res: GetServerSidePropsContext['res']
) => {
  const options: NextAuthOptions = {
    providers: [
      GoogleProvider({
        clientId: process.env.GOOGLE_CLIENT_ID ?? '',
        clientSecret: process.env.GOOGLE_CLIENT_SECRET ?? '',
        authorization: {
          url: 'https://accounts.google.com/o/oauth2/v2/auth?prompt=consent&access_type=offline&response_type=code',
        },
      }),
      CredentialsProvider({
        credentials: {
          username: { label: 'Username', type: 'text' },
          password: { label: 'Password', type: 'password' },
          workspace: { label: 'Workspace', type: 'text' },
        },
        async authorize(credentials) {
          const { login } = getApiClient();

          if (!credentials?.username || !credentials?.password) {
            return null;
          }

          try {
            const {
              data: { token, access_rights },
            } = await login({
              method: {
                mode: 'password',
                username: credentials.username,
                password: credentials.password,
              },
              workspace: credentials.workspace,
            });

            return {
              accessRights: access_rights,
              appToken: token,
              email: credentials.username,
              name: credentials.username,
              id: credentials.username,
            };
          } catch (e) {
            return null;
          }
        },
      }),
    ],
    callbacks: {
      async jwt({ token, account, profile, user }) {
        // Account is present only during sign in
        const isSigningIn = !!account;
        if (isSigningIn) {
          if (account.provider === 'google') {
            const cookies = new Cookies(req, res);
            const workspace = cookies.get('BOB_WORKSPACE');
            cookies.set(
              'userData',
              encodeURI(
                JSON.stringify({
                  email: profile?.email,
                  name: profile?.name,
                  workspace,
                })
              ),
              {
                sameSite: 'lax',
                path: '/',
              }
            );
            const { login } = getApiClient();
            try {
              const {
                data: { token: appToken, access_rights },
              } = await login({
                method: {
                  mode: 'google',
                  id_token: account.id_token ?? '',
                },
                workspace: workspace ?? '',
              });
              token.appToken = appToken;
              token.accessRights = access_rights;
            } catch (e) {
              res.writeHead(302, {
                Location: ROUTES.FORBIDDEN,
              });
              res.end();
            }
          }
          if (account.provider === 'credentials') {
            token.accessRights = (user as any).accessRights;
            token.appToken = (user as any).appToken;
          }
        }
        return token;
      },
      async session({ session, token }) {
        session.accessRights = token.accessRights;
        session.accessGroup = token.accessGroup ?? null;
        return session;
      },
    },
    pages: {
      error: ROUTES.FORBIDDEN,
    },
    secret: process.env.NEXTAUTH_SECRET,
    cookies: {
      sessionToken: {
        name: SESSION_COOKIE_NAME,
        options: cookieSettings,
      },
    },
    jwt: {
      encode: async ({ token }) => {
        if (token) {
          token.iat = Date.now() / 1000;
          const encodedToken = jwt.sign(
            token,
            process.env?.NEXTAUTH_SECRET ?? '',
            {
              algorithm: 'HS256',
            }
          );

          return encodedToken;
        }
        return '';
      },
      decode: async ({ token }) => {
        if (token) {
          return jwt.verify(token, process.env.NEXTAUTH_SECRET ?? '', {
            algorithms: ['HS256'],
          }) as JWT;
        }
        return null;
      },
    },
  };
  return options;
};

export default (req: NextApiRequest, res: NextApiResponse) => {
  return NextAuth(req, res, getNextAuthOptions(req, res));
};
