import jwt from 'jsonwebtoken';
import { camelCase } from 'lodash';
import { GetServerSidePropsContext } from 'next';
import { getServerSession, Session } from 'next-auth';

import { getNextAuthOptions } from 'pages/api/auth/[...nextauth]';

import { getCheckAnyAccessRight } from './accessRights';
import { AccessRight } from './api.types';
import {
  isModuleType,
  isNonModulePage,
  isRouteType,
  Modules,
  NonModulesPages,
  RESTRICTED_PATHS,
  ROUTES,
} from './routes';

export const SESSION_COOKIE_NAME = 'next-auth.session-token';

export interface AuthData {
  token: string | null;
  session: Session | null;
}

export const auth = async (
  ctx: GetServerSidePropsContext
): Promise<AuthData & { isRouteAccessPrevented?: boolean }> => {
  const authenticated = await getIsAuthenticated(ctx);
  if (!authenticated.isAuthenticated) {
    return { token: null, session: null };
  }
  const appToken = authenticated.appToken;
  const session = authenticated.session;
  const accessRights = authenticated.accessRights;

  if (!accessRights) {
    return { token: null, session: null };
  }
  const routePermission = checkForRoutePermission(accessRights, ctx);
  if (routePermission?.isUnauthorized) {
    return { token: appToken, session: session, isRouteAccessPrevented: true };
  }

  return { token: appToken, session };
};

export const getJwtToken = (req: GetServerSidePropsContext['req']) => {
  return req.cookies[SESSION_COOKIE_NAME];
};

const isCredentialsType = (
  credentials: any
): credentials is { appToken: string; accessRights: AccessRight[] } => {
  return (
    credentials &&
    typeof credentials === 'object' &&
    'appToken' in credentials &&
    'accessRights' in credentials
  );
};

export const getIsAuthenticated = async (ctx: GetServerSidePropsContext) => {
  const session = await getServerSession(
    ctx.req,
    ctx.res,
    getNextAuthOptions(ctx.req, ctx.res)
  );
  if (session && session.user && !session.user.image) {
    session.user.image = null;
  }

  // Sessions using accessGroup are legacy, log out the user so they can log in again
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  if (session?.accessGroup) {
    return {
      isAuthenticated: false,
      session: null,
      appToken: '',
      legacyToken: true,
    };
  }

  const token = getJwtToken(ctx.req) || null;

  let credentials;
  if (token) {
    credentials = jwt.decode(token);
  }

  return {
    isAuthenticated: !!session,
    session,
    appToken: isCredentialsType(credentials) ? credentials?.appToken : '',
    accessRights: isCredentialsType(credentials)
      ? (credentials?.accessRights as AccessRight[])
      : null,
  };
};

export const checkForRoutePermission = (
  accessRights: AccessRight[],
  ctx: GetServerSidePropsContext
) => {
  const exactPathMatch = RESTRICTED_PATHS.find(
    ({ path }) => ctx.req.url === path
  );
  const broaderPathMatch = RESTRICTED_PATHS.find(
    ({ path }) => ctx.req.url?.startsWith(path)
  );

  if (!exactPathMatch && !broaderPathMatch) return;

  const requiredAccess = (exactPathMatch || broaderPathMatch)?.access;
  const isAuthorized =
    requiredAccess && getCheckAnyAccessRight(accessRights, requiredAccess);
  if (!isAuthorized) {
    return { isUnauthorized: true };
  }
};

// Subdomain
export const getSubdomain = (req: GetServerSidePropsContext['req']) => {
  const isDashSubdomain =
    !!process.env.ENV &&
    ['local', 'development', 'test', 'staging'].includes(process.env.ENV);
  const subdomainPart = req.headers.host?.split('.')[0] ?? '';
  return isDashSubdomain
    ? subdomainPart?.split('-').slice(0, -1).join('-')
    : subdomainPart;
};

export const getCurrentPage = (ctx: GetServerSidePropsContext) => {
  const urlRoute = ctx.resolvedUrl.split('?')[0];
  const route = Object.keys(ROUTES).find(
    route => isRouteType(route) && urlRoute === ROUTES[route]
  );
  const formattedRoute = camelCase(route);
  const pageName: Modules | NonModulesPages =
    (isModuleType(formattedRoute) && Modules[formattedRoute]) ||
    (isNonModulePage(formattedRoute) && NonModulesPages[formattedRoute]) ||
    Modules.projectsList;

  return pageName;
};

export interface UserData {
  email: string;
  name: string;
  workspace: string;
}

export const getUserData = (
  ctx: GetServerSidePropsContext
): UserData | null => {
  let userData = null;

  try {
    userData = JSON.parse(decodeURI(ctx.req.cookies.userData ?? ''));
  } catch (e) {}

  if (userData) {
    return {
      email: userData.email,
      name: userData.name,
      workspace: userData.workspace,
    };
  } else {
    return null;
  }
};

export const loginRedirect = {
  redirect: {
    destination: ROUTES.LOGIN,
    permanent: false,
  },
};

export const homeRedirect = {
  redirect: {
    destination: ROUTES.HOME,
    permanent: false,
  },
};

export const autoLogoutRedirect = {
  redirect: {
    destination: ROUTES.AUTO_LOGOUT,
    permanent: false,
  },
};

export const maintenanceRedirect = {
  redirect: {
    destination: ROUTES.MAINTENANCE,
    permanent: false,
  },
};
