import { rgba } from 'polished';
import styled, { css } from 'styled-components';

import { hover, setTypography, setVh } from 'utils/styles/mixins';
import { colors } from 'utils/styles/theme';

export const Background = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: ${setVh(100)};
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  width: 700rem;
  border: 1px solid ${rgba(colors.outerSpace, 0.2)};
  padding: 40rem;
  border-radius: 20rem;
  gap: 40rem;
`;

export const LogoWrapper = styled.div`
  transform: scale(1.5);
  display: flex;
  justify-content: center;
  transform-origin: center;
  margin-bottom: 100rem;
`;

export const Title = styled.div`
  text-align: center;
  ${({ theme: { locale } }) => setTypography('heading2', locale)};
`;

export const Subtitle = styled.div`
  ${({ theme: { locale } }) => setTypography('body3', locale)};
  text-align: center;
  color: ${rgba(colors.outerSpace, 0.5)};
`;

export const ButtonWrapper = styled.div`
  margin: auto;
  padding-top: 40rem;
`;

export const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 45rem;
`;

export const SubFormText = styled.div`
  text-align: center;
  ${({ theme: { locale } }) => setTypography('caption', locale)};
  color: ${rgba(colors.outerSpace, 0.5)};
`;

export const ForgotPasswordButton = styled.div`
  ${({ theme: { locale } }) => setTypography('body5', locale)};
  color: ${colors.blueRibbon};
  text-align: right;
`;

export const ShowPasswordIcon = styled.button`
  width: 48rem;
  height: 48rem;
  cursor: pointer;
  margin-right: 20rem;

  ${hover(css`
    color: ${rgba(colors.outerSpace, 0.5)};
  `)}
`;
